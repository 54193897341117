/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import "@fontsource/oxygen"; // Defaults to weight 400.
@import '@fontsource/libre-baskerville';
html, body { 
    height: 100vh; 
    width: 100%; 
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif, var(--font-family); }

//*debug spacing. Remove when not in development
// * {
//     outline: 1px solid #f00 !important;
//   }

$page-name-color: #000000;
$page-name-font-size: 36px;
$page-font-family:  'Lato';

$headline-font-size: 56px;
$subheadline-font-size: 28px;

$hero-title-font-size: 36px;
$hero-border-color: #CBD0D3;

$page-text-color: #333333;
$page-text-font-size: 20px;

$page-body-contact-color: #000000;
$page-high-priority-color: #D10808;
