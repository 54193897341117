@font-face {
    font-family: Lato;
    src: url("./lato/Lato-Regular.ttf") format("truetype");
}

@font-face {
    font-family: Lato;
    src: url("./lato/Lato-Bold.ttf") format("truetype");
    font-weight: bold;
}
